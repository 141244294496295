import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  FaFileDownload,
  FaRedo,
  FaSave,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import ManageContext from "../../context/manage/manage.context";

import styles from "../../styles/Manage.module.css";
import DataTable, { createTheme } from "react-data-table-component";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaEllipsisV } from "react-icons/fa";
import { useSnackbar } from "notistack";

interface AllTemplateDocumentsTableProps {
  allDocs: any[];
}

export const AllTemplateDocumentsTable: React.FC<
  AllTemplateDocumentsTableProps
> = ({ allDocs }) => {
  const navigate = useNavigate();
  const { setCurrentDocumentHeaderId } = useContext(ManageContext);
  const [allDocumentData, setAllDocumentData] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;

  const darkTheme: any = {
    title: {
      fontSize: "18px",
      color: "red",
    },
    header: {
      fontSize: "16px",
      fontColor: "red",
      backgroundColor: "#363640",
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
      },
    },
  };

  createTheme("solarized", {
    background: {
      default: "transparent",
    },
  });

  useEffect(() => {
    const updatedDocs = allDocs?.map((doc) => ({
      Subject: doc?.templateName,
      TotalDocument: doc?.totalDocuments,
      SentOn: doc?.sentOnMail,
      Status: doc?.signerStatus,
      LastChanges: doc?.lastUpdated,
      HeaderId: doc?.templateHeaderId,
      senderStatus: doc?.senderStatus,
      templateDescription: doc?.templateDescription,
    }));
    setAllDocumentData(updatedDocs);
  }, [allDocs]);

  const caseSort = (rowA: any, rowB: any) => {
    const a = rowA.LastChanges;
    const b = rowB.LastChanges;
    return a > b ? 1 : b > a ? -1 : 0;
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteDocument = (documentHeaderId: string) => {
    console.log(`Deleting document with Header ID: ${documentHeaderId}`);
    // Add delete logic here
  };

  const handleSign = async (templateHeaderId: string, templateName: string) => {
    try {
      const res = await fetch(
        `${baseURL}/template/use-template-as-document/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            templateHeaderId,
            templateName,
          }),
        }
      );

      if (!res.ok) {
        throw new Error(`API Error: ${res.status} ${res.statusText}`);
      }

      const data = await res.json(); // Assuming the API response is in JSON format
      setCurrentDocumentHeaderId(data?.documentHeaderId);

      // Show success message
      enqueueSnackbar("Template successfully used as a document!", {
        variant: "success",
      });

      navigate("/documentDetails");
    } catch (err) {
      console.error("Failed to use template as document", err);

      // Show error message
      enqueueSnackbar(
        `Failed to use template: ${
          err instanceof Error ? err.message : "Unexpected error occurred."
        }`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSignPackageInfo = (documentHeaderId: string) => {
    setCurrentDocumentHeaderId(documentHeaderId);
    navigate("/documentDetails?id=3");
  };

  const getAuditData = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/audit/get-audit-document-wise?userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem(
          "companyId"
        )}&documentHeaderId=${documentHeaderId}`,
        {
          headers: {
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        }
      );
      const blob = await res.blob();
      saveAs(blob, "auditTrail.pdf");
    } catch (err) {
      console.error("Failed to download audit report", err);
    }
  };

  const getSignedDoc = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/audit/get-completed-document?userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem(
          "companyId"
        )}&documentHeaderId=${documentHeaderId}`,
        {
          headers: {
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        }
      );
      const blob = await res.blob();
      saveAs(blob, "signedDoc.zip");
    } catch (err) {
      console.error("Failed to download signed document", err);
    }
  };

  const handleResendDocument = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/upload/resend-documents-to-signers/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        {
          method: "POST", // Set request type to POST
          headers: {
            "Content-Type": "application/json", // Ensure content type is JSON
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            documentHeaderId: documentHeaderId, // Sending the payload
          }),
        }
      );

      // Check if the response is successful (status code 200–299)
      if (res.ok) {
        enqueueSnackbar("Document resent successfully", { variant: "success" });
      } else {
        // Handle any unsuccessful responses
        const errorData = await res.json();
        enqueueSnackbar(
          `Error: ${errorData.message || "Failed to resend document"}`,
          { variant: "error" }
        );
      }
    } catch (err) {
      console.error("Failed to resend document", err);
      enqueueSnackbar("An error occurred while resending the document", {
        variant: "error",
      });
    }
  };

  const handleRemoveFromTemplateDocument = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/template/save-documents-as-template/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        {
          method: "POST", // Set request type to POST
          headers: {
            "Content-Type": "application/json", // Ensure content type is JSON
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            documentHeaderId: documentHeaderId, // Sending the payload
          }),
        }
      );

      // Check if the response is successful (status code 200–299)
      if (res.ok) {
        enqueueSnackbar("Document saved successfully as template", {
          variant: "success",
        });
      } else {
        // Handle any unsuccessful responses
        const errorData = await res.json();
        enqueueSnackbar(
          `Error: ${
            errorData.message || "Failed to save document as template"
          }`,
          { variant: "error" }
        );
      }
    } catch (err) {
      console.error("Failed to saving the document as template", err);
      enqueueSnackbar(
        "An error occurred while saving the document as template",
        {
          variant: "error",
        }
      );
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const columns = [
    {
      name: "SUBJECT",
      selector: (row: any) => row.Subject,
      sortable: true,
      width: "35%", // Increased to take more space
      style: { fontSize: "14px", fontWeight: "bold" },
      sortFunction: caseSort,
    },
    {
      name: "DESCRIPTION",
      selector: (row: any) => row.templateDescription,
      sortable: true,
      center: true,
      width: "25%", // Reduced to match its content
    },
    // {
    //   name: "STATUS",
    //   selector: (row: any) => row.Status,
    //   sortable: true,
    //   center: true,
    //   width: "15%", // Appropriate size for the status text
    // },
    {
      name: "LAST CHANGED",
      selector: (row: any) =>
        moment(row.LastChanges).format("D MMM YY, h:mm A"),
      sortable: true,
      center: true,
      width: "25%", // Sufficient width for date and time
    },

    {
      name: "ACTIONS",
      cell: (row: any) => (
        <div
          className={styles.btn}
          onClick={() => handleSign(row?.HeaderId, row?.Subject)}
        >
          Use
        </div>
      ),
      center: true,
      width: "15%",
    },

    // {
    //   name: "MENU",
    //   cell: (row: any) => (
    //     <>
    //       <IconButton onClick={(event) => handleMenuOpen(event, row)}>
    //         <FaEllipsisV color="black" size={20} />
    //       </IconButton>
    //       <Menu
    //         anchorEl={anchorEl}
    //         open={Boolean(anchorEl) && selectedRow?.HeaderId === row.HeaderId}
    //         onClose={handleMenuClose}
    //         PaperProps={{
    //           style: { width: "250px" },
    //         }}
    //       >
    //         {row.Status === "completed" && (
    //           <>
    //             <MenuItem
    //               onClick={() => {
    //                 getAuditData(row.HeaderId);
    //                 handleMenuClose();
    //               }}
    //             >
    //               <FaFileDownload style={{ marginRight: "10px" }} />
    //               Download Audit Report
    //             </MenuItem>

    //             <MenuItem
    //               onClick={() => {
    //                 getSignedDoc(row.HeaderId);
    //                 handleMenuClose();
    //               }}
    //             >
    //               <FaFileDownload style={{ marginRight: "10px" }} />
    //               Download Signed Document
    //             </MenuItem>

    //             <MenuItem
    //               onClick={() => {
    //                 getSignedDoc(row.HeaderId);
    //                 getAuditData(row.HeaderId);
    //                 handleMenuClose();
    //               }}
    //             >
    //               <FaFileDownload style={{ marginRight: "10px" }} />
    //               Download Doc & Audit
    //             </MenuItem>
    //           </>
    //         )}

    //         {row.Status !== "completed" && row.senderStatus === "completed" && (
    //           <MenuItem
    //             onClick={() => {
    //               handleResendDocument(row.HeaderId);
    //               handleMenuClose();
    //             }}
    //           >
    //             <FaRedo style={{ marginRight: "10px" }} /> Resend Document
    //           </MenuItem>
    //         )}

    //         {row.Status !== "completed" && row.senderStatus === "completed" && (
    //           <MenuItem
    //             onClick={() => {
    //               handleRemoveFromTemplateDocument(row.HeaderId);
    //               handleMenuClose();
    //             }}
    //           >
    //             <FaTimes style={{ marginRight: "10px" }} /> Remove From Template
    //           </MenuItem>
    //         )}

    //         <MenuItem
    //           onClick={() => {
    //             handleDeleteDocument(row.HeaderId);
    //             handleMenuClose();
    //           }}
    //         >
    //           <FaTrash style={{ marginRight: "10px" }} /> Delete Document
    //         </MenuItem>

    //        <MenuItem
    //           onClick={() => {
    //             // handleSaveAsTemplate(row.HeaderId);
    //             handleMenuClose();
    //           }}
    //         >
    //           <FaSave style={{ marginRight: "10px" }} /> Save As Template
    //         </MenuItem>
    //       </Menu>
    //     </>
    //   ),
    //   center: true,
    //   width: "15%", // More space for the menu to accommodate all actions
    // },
  ];

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <div className="inline-block min-w-full align-middle">
          <div className="h-[80vh] overflow-scroll pb-10">
            <DataTable
              columns={columns}
              data={allDocumentData}
              theme="solarized"
              defaultSortAsc={false}
              customStyles={darkTheme}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
