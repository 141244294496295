import React, { useState } from "react";
import UploadPopup from "./UploadPopup";

interface SideBarProps {
  children?: React.ReactNode;
  type: string;
  setType: any;
}

const TemplateSideBar: React.FC<SideBarProps> = ({
  type,
  setType,
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-no-wrap h-full">
      <div className="w-1/6 sm:relative bg-primary-900 shadow md:h-full flex-col hidden sm:flex py-4 h-full pl-4">
        <div className="mb-4">
          <h4 className="opacity-90 text-black">Templates</h4>
        </div>

        <p
          className={`text-lg ml-2 opacity-80 cursor-pointer text-black ${
            type === "all" && "text-green"
          }`}
          onClick={() => setType("all")}
        >
          All Templates
        </p>
        <div className="pl-4">
          <ol className="relative dark:border-gray-700">
            {/* <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100 text-black"
              onClick={openModal} // Open modal on click
            >
              <span className="text-lg ml-2">Add New Template</span>
            </li> */}

            <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100 text-black"
              onClick={() => setType("all")}
            >
              <span
                className={`text-lg ml-2 ${type === "all" && "text-green"}`}
              >
                All
              </span>
            </li>

            {/* <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100 text-black"
              onClick={() => setType("pending")}
            >
              <span
                className={`text-lg ml-2 ${type === "pending" && "text-green"}`}
              >
                Favourites
              </span>
            </li> */}
          </ol>
        </div>
      </div>

      {isModalOpen && <UploadPopup open={isModalOpen} onClose={closeModal} />}

      {children}
    </div>
  );
};

export default TemplateSideBar;
