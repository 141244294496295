import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const MyPay = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const emailId = searchParams.get("email")!;
  const name = searchParams.get("name")!;

  // runal
  const planId = searchParams.get("planId")!;
  const planType = searchParams.get("planType")!;
  const planName = searchParams.get("planName")!;

  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    handlePaymentLink();
  }, []);

  const handlePaymentLink = async () => {
    try {
      const paymentLinkResponse = await axios.post(
        `${baseURL}/v1/my-pay/create-payment-link`,
        {
          userMailId: emailId,
          planId: planId,
          planType: planType,
          description: planName,
          // amount: "8.00",
          // Add any additional required fields based on your backend's expectations
        }
      );

      const paymentLink = paymentLinkResponse.data;
      if (paymentLink) {
        // Open the payment link
        // window.open(paymentLink);
        window.location.href = paymentLink;
      }
    } catch (error) {
      console.error("Error creating payment link:", error);
      enqueueSnackbar("Failed to create payment link", { variant: "error" });
    }
  };

  return <div></div>;
};

export default MyPay;
