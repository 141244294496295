import React, { useEffect, useState } from "react";
import { Button, Grid, Box, Typography, CircularProgress } from "@mui/material";
import { Headers } from "./layouts/Headers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { PlansPopup } from "./PlansPopup";

interface Feature {
  featureId: string;
  featureName: string;
  description: string | null;
}

interface Plan {
  planId: string;
  planName: string;
  description: string | null;
  monthlyPrice: string | null;
  annualPrice: string | null;
  featuresList: Feature[];
}

interface PricingPlanProps {
  id: string;
  title: string;
  price: string;
  priceDifference: string;
  details: { featureName: string; description: string | null }[]; // Updated details structure
  link: (plan: PricingPlanProps) => void;
}

const PricingPlan: React.FC<PricingPlanProps> = ({
  id,
  title,
  price,
  priceDifference,
  details,
  link,
}) => (
  <Box
    sx={{
      border: "1px solid #ddd",
      padding: 3,
      borderRadius: 2,
      textAlign: "center",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      background: "white",
      maxHeight: title === "Enterprise" ? "auto" : "700px", // For Enterprise, let it grow
      minHeight: "550px", // Set a minimum height for consistency in other cards
      "&:hover": {
        transform: "scale(1.04)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.2)",
        color: "blue",
      },
    }}
  >
    <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>
      {title}
    </Typography>

    <Typography
      variant={title === "Enterprise" ? "h5" : "h4"}
      sx={{ fontWeight: "bold", color: "#00796b" }}
    >
      {title === "Enterprise"
        ? "Customize bill/month"
        : title === "Free"
        ? "Free for 1 Month"
        : price}
    </Typography>

    <Typography variant="h5" sx={{ fontWeight: "bold", color: "#00796b" }}>
      {title === "Enterprise" || title === "Free" ? "" : priceDifference}
    </Typography>

    {/* Features List */}
    <Box sx={{ marginBottom: 2, flexGrow: 1 }}>
      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
        {details.map((detail, index) => (
          <li key={index}>
            <Typography
              variant="body2"
              sx={{ color: "#555", marginTop: "10px", textAlign: "left" }}
            >
              <span>✔️</span>
              <span style={{ marginLeft: "5px" }}>{detail?.featureName}</span>
            </Typography>
          </li>
        ))}
      </ul>
    </Box>

    {/* Conditionally render button based on planName */}
    <Box sx={{ marginTop: "auto" }}>
      {title !== "Free" && title !== "Enterprise" && (
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            link({ id, title, price, priceDifference, details, link })
          }
        >
          Subscribe
        </Button>
      )}

      {/* Navigate to the URL for Enterprise plan */}
      {title === "Enterprise" && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            window.open("https://easysign.us.com/contact-sales", "_blank")
          }
        >
          Customize Plan
        </Button>
      )}
    </Box>
  </Box>
);

export const Plans: React.FC = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [activePlan, setActivePlan] = useState<string>("monthly");
  const [plansPopupOpen, setPlansPopupOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PricingPlanProps | null>(
    null
  );
  const [annualPlans, setAnnualPlans] = useState<PricingPlanProps[]>([]);
  const [monthlyPlans, setMonthlyPlans] = useState<PricingPlanProps[]>([]);

  const transformPlans = (plans: Plan[], isAnnual: boolean) =>
    plans
      .filter((plan) => (isAnnual ? plan.annualPrice : plan.monthlyPrice))
      .map((plan) => {
        const annualPriceByMonthlyBySelfFormula = (
          Number(plan.annualPrice) / 12
        ).toFixed(2);
        const priceDifference = (
          Number(plan.monthlyPrice) - Number(annualPriceByMonthlyBySelfFormula)
        ).toFixed(2);

        return {
          id: plan.planId.toString(),
          title: plan.planName,
          price: isAnnual
            ? `$${annualPriceByMonthlyBySelfFormula}/month`
            : `$${plan.monthlyPrice}/month`,
          priceDifference: isAnnual
            ? `(Savings: $${priceDifference}/month)`
            : "",
          details: plan.featuresList.map((feature) => ({
            featureName: feature.featureName,
            description: feature.description,
          })),
          link: handlePlansClick,
        };
      });

  const handlePlansClick = (plan: PricingPlanProps) => {
    setSelectedPlan(plan);
    setPlansPopupOpen(true);
  };

  const handleGetPlans = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/plans/all-plans`, {
        headers: {
          key: headerKey || "",
          Authorization: `Bearer ${
            sessionStorage.getItem("accessToken") || ""
          }`,
        },
      });

      const plans: Plan[] = response.data;

      setAnnualPlans(transformPlans(plans, true));
      setMonthlyPlans(transformPlans(plans, false));
    } catch (error) {
      enqueueSnackbar("Failed to fetch plans. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPlans();
  }, []);

  const handleSetPlan = (planType: string) => {
    setActivePlan(planType); // Dynamically set the active plan
  };

  return (
    <>
      <Headers />
      <div style={{ textAlign: "center", margin: "20px 10%" }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "#004d40", marginBottom: "10px" }}
        >
          Choose Your Plan
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          gap={2}
          sx={{ marginBottom: 3 }}
        >
          <Button
            variant={activePlan === "monthly" ? "contained" : "outlined"}
            onClick={() => handleSetPlan("monthly")}
          >
            Monthly
          </Button>

          <Button
            variant={activePlan === "annual" ? "contained" : "outlined"}
            onClick={() => handleSetPlan("annual")}
          >
            Annual
          </Button>
        </Box>
      </div>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginLeft: "25px", marginRight: "25px" }}>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{ marginBottom: 4 }}
          >
            {(activePlan === "annual" ? annualPlans : monthlyPlans).map(
              (plan) => (
                <Grid item xs={12} sm={6} md={3} key={plan.id}>
                  <PricingPlan {...plan} />
                </Grid>
              )
            )}
          </Grid>
        </div>
      )}

      <PlansPopup
        open={plansPopupOpen}
        onClose={() => setPlansPopupOpen(false)}
        plan={selectedPlan}
        annualPlans={annualPlans}
        monthlyPlans={monthlyPlans}
        activePlan={activePlan}
      />
    </>
  );
};
