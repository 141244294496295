import React, { useContext, useState, useRef, useEffect } from "react";
import { NavLink as Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem, IconButton } from "@mui/material";
import SignatureContext from "../../context/signature/Signature.context";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { ProfilePopup } from "./ProfilePopup";
import { useSnackbar } from "notistack";
import { FaBell } from "react-icons/fa";
import axios from "axios";
import { BookingHistory } from "./BookingHistory";

interface HeadersProps {}

export const Headers: React.FC<HeadersProps> = () => {
  const { pathname } = useLocation();

  // const { userName } = useContext(SignatureContext);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profilePopupOpen, setProfilePopupOpen] = useState(false);

  const [BookingHistoryPopupOpen, setBookingHistoryPopupOpen] = useState(false);

  const [plansPopupOpen, setPlansPopupOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const defaultProfileImage =
    "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Images.png";

  const [profileImage, setProfileImage] = useState<string>(defaultProfileImage);
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [remainingDays, setremainingDays] = useState<number>();

  const [planName, setPlanName] = useState<string>("");

  const baseURL = process.env.REACT_APP_API_URL || "";
  const headerKey = process.env.REACT_APP_API_HEADER_KEY || "";

  const notificationsMenuRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
    enqueueSnackbar("Successfully logged out!", { variant: "success" });
    handleClose();
  };

  const handleProfileClick = () => {
    setProfilePopupOpen(true);
    handleClose();
  };

  const handleBookingHistoryClick = () => {
    setBookingHistoryPopupOpen(true);
    handleClose();
  };

  const handlePlansClick = () => {
    // setPlansPopupOpen(true);
    // handleClose();

    navigate("/plans");
  };

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      notificationsMenuRef.current &&
      !notificationsMenuRef.current.contains(event.target as Node)
    ) {
      setNotificationsOpen(false);
    }
  };

  const fetchUserDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/auth/get-user-details/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        {
          headers: {
            key: headerKey,
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );

      const {
        name = "",
        email = "",
        profileImage = "",
        planDetails: {
          subscriptionRemainingDays: remainingDaysbyAPI = "",
          planName: planNameByApi = "",
          planId: planIdplanIdByApi = "",
        } = {},
      } = response?.data || {};

      // Update state with the fetched values
      setUserName(name);
      setEmail(email);

      setremainingDays(remainingDaysbyAPI);

      setPlanName(planNameByApi);

      // Process and set profile image
      const base64Image = profileImage.startsWith("data:image")
        ? profileImage
        : defaultProfileImage;
      setProfileImage(base64Image);
    } catch (error) {
      enqueueSnackbar("Failed to fetch user details", { variant: "error" });
      console.error("Error fetching user details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [baseURL, headerKey, enqueueSnackbar]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const notifications = [
    { id: 1, message: "📧 Your document has been sent for signing." },
    { id: 2, message: "📝 You have a new document to review." },
    { id: 3, message: "✅ Document signing completed successfully!" },
    { id: 4, message: "🔄 Document status has been updated." },
    { id: 5, message: "🚀 Document has been finalized." },
    { id: 6, message: "⏳ Reminder: Document signing is due soon." },
    { id: 7, message: "❗ Action required: Review document details." },
  ];

  // Inline styles
  const notificationsMenuStyle: React.CSSProperties = {
    display: notificationsOpen ? "block" : "none",
    position: "absolute",
    top: "60px", // Adjust as needed
    right: "10px", // Adjust as needed
    background: "white",
    border: "1px solid #ddd",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    width: "250px", // Adjust as needed
    zIndex: 1000, // Ensure the notifications menu is on top
  };

  const listItemStyle: React.CSSProperties = {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    color: "black", // Text color black
  };

  const lastListItemStyle: React.CSSProperties = {
    ...listItemStyle,
    borderBottom: "none",
  };

  return (
    <>
      <nav className="flex items-center justify-between flex-wrap bg-primary-1 p-2 lg:px-8 shadow-lg">
        <>
          <Link
            to="/home"
            className="flex items-center flex-no-shrink text-black mr-6"
          >
            <span className="font-semibold text-2xl tracking-tight">
              EasySign
            </span>
          </Link>
          <div className="block lg:hidden">
            <button className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white">
              <svg
                className="h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            {pathname !== "/login" && (
              <div className="text-sm lg:flex-grow">
                <Link to="/home" className="mainTitle">
                  <span className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-white mr-4 text-base">
                    Home
                  </span>
                </Link>
                <Link to="/manage" className="mainTitle">
                  <span className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-white mr-4 text-base">
                    Manage
                  </span>
                </Link>

                {/* <Link to="/template" className="mainTitle">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-white mr-4 text-base">
                  Template
                </span>
              </Link> */}
              </div>
            )}
            {pathname !== "/login" && (
              <div className="flex items-center relative">
                <span className="text-black pr-3 cursor-default">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "red",
                      marginRight: "12px",
                    }}
                  >
                    {/* {planName} Plan  */}
                    {remainingDays} Days Left.
                  </span>

                  {userName}

                  {/* <button
                  onClick={() => navigate("/plans")}
                  style={{
                    fontSize: "16px",
                    color: "#FFFFFF", // White text by default
                    background: "blue", // Dark blue background
                    border: "none",
                    borderRadius: "24px", // Border radius set to 15px
                    padding: "8px 16px",
                    cursor: "pointer",
                    marginLeft: "10px",
                    transition: "background 0.3s ease, color 0.3s ease", // Smooth transition
                  }}
                  onMouseEnter={(e) => {
                    // On hover, reverse the colors
                    e.currentTarget.style.background = "white";
                    e.currentTarget.style.color = "blue";
                  }}
                  onMouseLeave={(e) => {
                    // Revert back to the original colors when hover ends
                    e.currentTarget.style.background = "blue";
                    e.currentTarget.style.color = "#FFFFFF";
                  }}
                >
                  View Plans
                </button> */}
                </span>

                {/* <IconButton onClick={toggleNotifications}>
                <FaBell size={24} />
                <span
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                  }}
                >
                  {notifications.length}
                </span>
              </IconButton> */}

                <IconButton onClick={handleClick}>
                  <Avatar
                    alt="Profile"
                    src={profileImage}
                    sx={{
                      // width: 100,
                      // height: 100,
                      border: "2px solid #1976d2",
                      borderRadius: "50%",
                    }}
                  />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuItem onClick={handleProfileClick}>Profile</MenuItem>

                  <MenuItem onClick={handlePlansClick}>Plans</MenuItem>

                  <MenuItem
                    // onClick={handleBookingHistoryClick}
                    onClick={() => navigate("/billings")}
                  >
                    Billing
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      window.open("https://easysign.us.com/support/", "_blank");
                      handleClose();
                    }}
                  >
                    Support
                  </MenuItem>

                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
                <div ref={notificationsMenuRef} style={notificationsMenuStyle}>
                  <ul
                    style={{ listStyleType: "none", padding: "0", margin: "0" }}
                  >
                    {notifications.map((notification) => (
                      <li
                        key={notification.id}
                        style={
                          notification.id === notifications.length
                            ? lastListItemStyle
                            : listItemStyle
                        }
                      >
                        {notification.message}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </>
      </nav>

      {remainingDays === 0 && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "14px",
              backgroundColor: "#FF7276", // Red background
              color: "#FFFFFF", // White text
              width: "100%",
              textAlign: "center",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: "12px",
                width: "auto", // Ensures no unnecessary width constraints
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  aria-label="Alert:"
                  fill="currentColor"
                  focusable="false"
                  role="img"
                  style={{ color: "#FFFFFF" }}
                >
                  <path d="M22.74 11.41 12.59 1.26a.83.83 0 0 0-1.18 0L1.26 11.41a.83.83 0 0 0 0 1.18l10.15 10.15a.83.83 0 0 0 1.18 0l10.15-10.15a.83.83 0 0 0 0-1.18zM11 6h2v8h-2zm1 12.3a1.3 1.3 0 1 1 1.3-1.3 1.3 1.3 0 0 1-1.3 1.3z"></path>
                </svg>
              </div>
              <div>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Your {planName} plan has expired. You can renew or upgrade
                  your plan.{" "}
                </span>
                <span
                  onClick={() => navigate("/plans")}
                  style={{
                    fontSize: "16px",
                    color: "#FFFFFF",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  View Plans.
                </span>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Profile Popup */}
      <ProfilePopup
        open={profilePopupOpen}
        onClose={() => {
          setProfilePopupOpen(false);
          fetchUserDetails();
        }}
      />

      <BookingHistory
        open={BookingHistoryPopupOpen}
        onClose={() => {
          setBookingHistoryPopupOpen(false);
          // fetchUserDetails();
        }}
      />
    </>
  );
};
