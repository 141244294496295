import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

interface BookingHistoryProps {
  open: boolean;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const BookingHistory: React.FC<BookingHistoryProps> = ({
  open,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const baseURL = process.env.REACT_APP_API_URL || "";
  const headerKey = process.env.REACT_APP_API_HEADER_KEY || "";

  const [billingHistory, setBillingHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const calculateDaysLeft = (endDate: string) => {
    const currentDate = new Date();
    const end = new Date(endDate);
    const diffTime = end.getTime() - currentDate.getTime();
    return Math.ceil(diffTime / (1000 * 3600 * 24)); // Calculate days left
  };

  useEffect(() => {
    if (open) {
      const fetchBillingHistory = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `${baseURL}/v1/my-pay/get-billing-history?emailId=${sessionStorage.getItem(
              "userEmail"
            )}&userId=${sessionStorage.getItem(
              "userId"
            )}&companyId=${sessionStorage.getItem("companyId")}`,
            {
              headers: {
                key: headerKey,
                Authorization: `Bearer ${sessionStorage.getItem(
                  "accessToken"
                )}`,
              },
            }
          );

          setBillingHistory(response.data || []);
        } catch (error) {
          enqueueSnackbar("Failed to fetch billing history", {
            variant: "error",
          });
          console.error("Error fetching billing history:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchBillingHistory();
    }
  }, [open, baseURL, headerKey, enqueueSnackbar]);

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll="paper"
      fullWidth={true}
      maxWidth="md"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        Billing History
      </BootstrapDialogTitle>
      <Divider />
      <DialogContent dividers>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="center">Plan Name</TableCell>
                  <TableCell align="center">Start Date</TableCell>
                  <TableCell align="center">End Date</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Days Left</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billingHistory.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {item.planName || "N/A"}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(item.startDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(item.endDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center">{item.status || "N/A"}</TableCell>
                    <TableCell align="center">{item.amount || "N/A"}</TableCell>
                    <TableCell align="center">
                      {calculateDaysLeft(item.endDate)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="warning">
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
