import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface ProfilePopupProps {
  open: boolean;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const ProfilePopup: React.FC<ProfilePopupProps> = ({
  open,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const baseURL = process.env.REACT_APP_API_URL || "";
  const headerKey = process.env.REACT_APP_API_HEADER_KEY || "";

  const defaultProfileImage =
    "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Images.png";

  const [profileImage, setProfileImage] = useState<string>(defaultProfileImage);
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [remainingDays, setRemainingDays] = useState<string>("");
  const [planExpirationDate, setplanExpirationDate] = useState<string>("");

  const [planName, setPlanName] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      const fetchUserDetails = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `${baseURL}/auth/get-user-details/${sessionStorage.getItem(
              "userId"
            )}/${sessionStorage.getItem("companyId")}`,
            {
              headers: {
                key: headerKey,
                Authorization: `Bearer ${sessionStorage.getItem(
                  "accessToken"
                )}`,
              },
            }
          );

          const {
            name = "",
            email = "",
            profileImage = "",
            planDetails: {
              subscriptionRemainingDays: remainingDaysbyAPI = "",
              planName = "",
              subscriptionEndDate = "",
            } = {},
          } = response?.data || {};

          // Update state with the fetched values
          setUserName(name);
          setEmail(email);
          setRemainingDays(remainingDaysbyAPI);
          setPlanName(planName);

          setplanExpirationDate(subscriptionEndDate);

          // Process and set profile image
          const base64Image = profileImage.startsWith("data:image")
            ? profileImage
            : defaultProfileImage;
          setProfileImage(base64Image);
        } catch (error) {
          enqueueSnackbar("Failed to fetch user details", { variant: "error" });
          console.error("Error fetching user details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchUserDetails();
    }
  }, [open, baseURL, headerKey, enqueueSnackbar]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (!validTypes.includes(file.type)) {
        enqueueSnackbar("Only JPG, JPEG, and PNG files are allowed.", {
          variant: "error",
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setProfileImage(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    setLoading(true);

    // If the profileImage is the default one, send an empty string
    const imageToSend =
      profileImage === defaultProfileImage ? "" : profileImage;

    const payload = {
      userName,
      userImg: imageToSend, // Send empty string if default image is used
    };

    try {
      // Save the profile data
      await axios.post(
        `${baseURL}/auth/update-user-details/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        payload,
        {
          headers: {
            key: headerKey,
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );

      // Get the current value from sessionStorage and increment it
      let savedValue = sessionStorage.getItem("profileSaveCount");
      if (!savedValue) {
        savedValue = "0"; // Default to 0 if not set
      }

      // Increment the value
      const newValue = parseInt(savedValue, 10) + 1;

      // Save the updated value in sessionStorage
      sessionStorage.setItem("profileSaveCount", newValue.toString());

      enqueueSnackbar("Profile saved successfully", { variant: "success" });
      onClose();
    } catch (error) {
      enqueueSnackbar("Failed to save profile", { variant: "error" });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll="paper"
      fullWidth={true}
      maxWidth="sm"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        Profile
      </BootstrapDialogTitle>
      <Divider />
      <DialogContent dividers>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box position="relative">
              <Avatar
                alt="Profile"
                src={profileImage} // Display base64 image or default image
                sx={{
                  width: 100,
                  height: 100,
                  border: "2px dashed #4CAF50",
                  borderRadius: "50%",
                  boxShadow: "0px 0px 12px 6px rgba(255, 223, 0, 0.3)",
                  backgroundColor: "#FFF",
                }}
              />
              <input
                accept="image/jpeg, image/jpg, image/png"
                type="file"
                style={{ display: "none" }}
                id="profile-image-input"
                onChange={handleImageChange}
              />
              <label htmlFor="profile-image-input">
                <IconButton
                  color="primary"
                  aria-label="edit profile image"
                  component="span"
                  sx={{
                    position: "absolute",
                    bottom: -5,
                    right: -5,
                    backgroundColor: "white",
                  }}
                >
                  <EditIcon />
                </IconButton>
              </label>
            </Box>
            <TextField
              margin="dense"
              label="Name"
              fullWidth
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              variant="outlined"
              sx={{ marginTop: 4 }}
            />
            <TextField
              margin="dense"
              label="Email"
              fullWidth
              value={email}
              disabled
              variant="outlined"
              sx={{ marginTop: 2 }}
            />

            {/* <Box sx={{ marginTop: 1, width: "100%" }}>
              <h3>Active Plan</h3>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Plan Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Days Left</strong>
                    </TableCell>

                    <TableCell>
                      <strong>Subscription End Date</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{planName}</TableCell>
                    <TableCell>{remainingDays} days</TableCell>
                    <TableCell>
                      {new Date(planExpirationDate).toLocaleDateString(
                        "en-GB",
                        { year: "numeric", month: "short", day: "numeric" }
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box> */}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          Close
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Profile"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
