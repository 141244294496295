import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import { Headers } from "./layouts/Headers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import DownloadIcon from "@mui/icons-material/Download";
// import jsPDF from "jspdf";
// import "jspdf-autotable";

export const Billings: React.FC = () => {
  const baseURL = process.env.REACT_APP_API_URL || "";
  const headerKey = process.env.REACT_APP_API_HEADER_KEY || "";
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [billings, setBillings] = useState<any[]>([]);
  const [activePlan, setActivePlan] = useState<any | null>(null);

  const handleGetPlans = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/v1/my-pay/get-billing-history?emailId=${sessionStorage.getItem(
          "userEmail"
        )}&userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem("companyId")}`,
        {
          headers: {
            key: headerKey,
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );
      setBillings(response.data || []);
    } catch (error) {
      enqueueSnackbar("Failed to fetch billing history. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPDF = () => {
    // const doc = new jsPDF();
    // doc.text("Billing History", 14, 10);
    // doc.autoTable({
    //   head: [["Plan Name", "Plan Type", "Amount ($)", "Start Date", "End Date", "Status"]],
    //   body: billings.map((plan) => [
    //     plan.planName,
    //     plan.planType,
    //     plan.amount,
    //     formatDate(plan.startDate),
    //     formatDate(plan.endDate),
    //     plan.status,
    //   ]),
    // });
    // doc.save("BillingHistory.pdf");
  };

  useEffect(() => {
    handleGetPlans();
  }, []);

  const fetchUserDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/auth/get-user-details/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        {
          headers: {
            key: headerKey,
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );

      setActivePlan(response?.data?.planDetails || null);
    } catch (error) {
      enqueueSnackbar("Failed to fetch user details", { variant: "error" });
      console.error("Error fetching user details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [baseURL, headerKey]);

  const formatDate = (date: string | null) => {
    if (!date) return "";
    const newDate = new Date(date);
    return newDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <>
      <Headers />

      <div style={{ width: "100%", textAlign: "center", margin: "20px auto" }}>
        <Typography variant="h4" style={{ fontWeight: "bold", color: "#3f51b5" }}>
          Billing History
        </Typography>
      </div>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Active Plan Section */}
          <Box
            sx={{
              width: "calc(100% - 60px)",
              margin: "20px auto",
              backgroundColor: "#fff",
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            {activePlan ? (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="10px"
                >
                  <Typography
                    variant="h5"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Active Plan
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Star />}
                    onClick={() => navigate("/plans")}
                  >
                    Upgrade Plan
                  </Button>
                </Box>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                    gap: "15px",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#b9cbfb",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="body1" style={{ color: "#333" }}>
                      <strong>Plan Name:</strong>{" "}
                      <span>{activePlan.planName}</span>
                    </Typography>
                  </div>
                  

                  <div
                    style={{
                      backgroundColor: "#b9cbfb",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="body1" style={{ color: "#333" }}>
                      <strong>Remaining Days:</strong>{" "}
                      {/* <span>{formatDate(activePlan.startDate)}</span> */}
                      <span>{activePlan.subscriptionRemainingDays}</span>
                    </Typography>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#b9cbfb",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="body1" style={{ color: "#333" }}>
                      <strong>Subscription End Date:</strong>{" "}
                      <span>{formatDate(activePlan.subscriptionEndDate)}</span>
                    </Typography>
                  </div>
                </div>
              </>
            ) : (
              <Typography variant="body1" style={{ color: "#d32f2f" }}>
                No active plan found.
              </Typography>
            )}
          </Box>

          {/* Billing History Table */}
          <TableContainer
            component={Paper}
            style={{
              margin: "20px auto",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "calc(100% - 60px)",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom="10px"
            >
              <Typography
                variant="h5"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Previous Billing Details
              </Typography>

              {/* <Button
  variant="contained"
  color="primary"
  startIcon={<DownloadIcon />}
  onClick={handleDownloadPDF}
>
  Download
</Button> */}
            </Box>

            <Table>
              <TableHead>
                <TableRow>
                <TableCell style={{ color: "#3f51b5" }}>
                    <strong>Payment Date</strong>
                  </TableCell>
                  <TableCell style={{ color: "#3f51b5" }}>
                    <strong>Plan Name</strong>
                  </TableCell>
                  <TableCell style={{ color: "#3f51b5" }}>
                    <strong>Plan Type</strong>
                  </TableCell>
                  <TableCell style={{ color: "#3f51b5" }}>
                    <strong>Amount ($)</strong>
                  </TableCell>
                  <TableCell style={{ color: "#3f51b5" }}>
                    <strong>Start Date</strong>
                  </TableCell>
                  <TableCell style={{ color: "#3f51b5" }}>
                    <strong>End Date</strong>
                  </TableCell>
                  <TableCell style={{ color: "#3f51b5" }}>
                    <strong>Status</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billings.map((plan) => (
                  <TableRow key={plan.planId}>
                    <TableCell>{formatDate(plan.creationDate)}</TableCell>
                    <TableCell>{plan.planName}</TableCell>
                    <TableCell>{plan.planType}</TableCell>
                    <TableCell>{plan.amount}</TableCell>
                    <TableCell>{formatDate(plan.startDate)}</TableCell>
                    <TableCell>{formatDate(plan.endDate)}</TableCell>
                    <TableCell>{plan.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};
