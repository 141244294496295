import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { BiMailSend } from "react-icons/bi";
import DocumentDetailsContext from "../../context/DocumentsDetails/documentDetails.context";
import { BsFileWord } from "react-icons/bs";
import { CheckBox } from "../helpers/CheckBox";
import { demoData } from "../helpers/Constants";
import FileView from "../fileuploader/FileView";
import axios from "axios";
import ManageContext from "../../context/manage/manage.context";
import { LinearProgress } from "@mui/material";
import { DocumentNavigator } from "./DocumentNavigator";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

interface ReviewDetailsProps {
  type: number;
  setType: any;
}

// file data
const sortByOrder = (a: any, b: any) =>
  a.signingOrder < b.signingOrder ? -1 : 1;

export const ReviewDetails: React.FC<ReviewDetailsProps> = ({
  type,
  setType,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ReviewPageDetails, setReviewPageDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;

  const { currentDocumentHeaderId } = useContext(ManageContext);

  const handleChange = (event: SelectChangeEvent, expId: string) => {
    let docvar = { ...ReviewPageDetails.documentPreferencesModel };
    docvar[expId] = event.target.value;
    setReviewPageDetails({
      ...ReviewPageDetails,
      documentPreferencesModel: docvar,
    });
  };

  const getReviewPageDetails = async () => {
    const resp = await axios.get(
      `${baseURL}/upload/get-document-details-for-review?userId=${sessionStorage.getItem(
        // `${baseURL}/template/use-template-as-document?userId=${sessionStorage.getItem(

        "userId"
      )}&companyId=${sessionStorage.getItem(
        "companyId"
      )}&documentHeaderId=${currentDocumentHeaderId}`,
      {
        headers: {
          key: "1b1c9e58-2acf-41dd-b31b-03c147b8cef2",
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      }
    );
    if (resp.data) {
      setReviewPageDetails(resp?.data);
      setLoading(false);
    }
  };

  const postReviewDetails = async () => {
    // setLoading(true);
    setSubmitLoader(true);
    const headers = {
      key: "1b1c9e58-2acf-41dd-b31b-03c147b8cef2",
      Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    };
    const body = ReviewPageDetails;
    const resPost = await axios.post(
      `${baseURL}/upload/save-documents-for-signers/${sessionStorage.getItem(
        "userId"
      )}/${sessionStorage.getItem("companyId")}/true`,
      body,
      {
        headers: headers,
      }
    );
    if (resPost) {
      // setLoading(false);
      setSubmitLoader(false);
      getReviewPageDetails();
      console.log("post api response", resPost);
      enqueueSnackbar("Document has been sent to recipients", {
        variant: "success",
      });
      navigate(
        "/home?userId=" +
          sessionStorage.getItem("userId") +
          "&companyId=" +
          sessionStorage.getItem("companyId")
      );
    }
  };

  console.log("ReviewPageDetails", ReviewPageDetails);
  useEffect(() => {
    getReviewPageDetails();
  }, []);

  const limit = 1000;
  const [mailMessageLength, setFirstLength] = useState(0);
  const handleChangeDoc = (e: any) => {
    setReviewPageDetails({
      ...ReviewPageDetails,
      [e.target.name]: e.target.value,
    });
    // console.log("QWERT", ReviewPageDetails);
  };

  const handleMessageChange = (e: any) => {
    setReviewPageDetails({
      ...ReviewPageDetails,
      [e.target.name]: e.target.value,
    });
    console.log("hello" + e.target.name);

    if ((e.target.name = "mailMessage")) {
      setFirstLength(e.target.value.length);
    }
  };
  const handleChangeCheckboxDoc = (e: any, name: string) => {
    let docvar = { ...ReviewPageDetails.documentPreferencesModel };
    docvar[name] = e.target.checked;

    setReviewPageDetails({
      ...ReviewPageDetails,
      documentPreferencesModel: docvar,
    });
  };
  const handleSave = () => {
    postReviewDetails();
  };

  return (
    <>
      <DocumentNavigator
        type={type}
        setType={setType}
        handleDataSave={handleSave}
        currentDocInfo={ReviewPageDetails}
      />
      <div className="p-4 w-3/4 mx-auto ">
        {submitLoader && (
          <div className=" flex items-center justify-center p-4">
            <CircularProgress />{" "}
          </div>
        )}
        {!submitLoader && (
          <>
            <div className="w-full border-secondary bg-primary-900 p-4 rounded-5 border-opacity-70 relative pb-6 drop-shadow-md">
              {loading ? (
                <div className="bg-primary-1 mt-2 roundedcurrentDocumentId relative w-4/5 m-auto flex items-center justify-center p-4">
                  <div className="w-full">
                    <LinearProgress
                      color="inherit"
                      style={{
                        backgroundColor: "bg-green",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex items-start flex-col justify-start p-2">
                  <div className="flex items-center justify-start w-full opacity-80">
                    <div className="flex flex-col items-start relative w-full ">
                      <p className="text-black absolute top-[-25px]">
                        Document Package Name
                      </p>
                      <input
                        readOnly={
                          ReviewPageDetails.documentPreferencesModel
                            .senderStatus !== "completed"
                            ? false
                            : true
                        }
                        className="w-full px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                        value={
                          ReviewPageDetails && ReviewPageDetails?.envelopeName
                            ? ReviewPageDetails?.envelopeName
                            : ""
                        }
                        name="envelopeName"
                        // placeholder="abc.pdf"
                        onChange={(e) => {
                          handleChangeDoc(e);
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-start w-full mt-6 opacity-80">
                    <div className="flex flex-col items-start relative w-full">
                      <p className="text-black absolute top-[-25px]">
                        Email Subject
                      </p>
                      <input
                        readOnly={
                          ReviewPageDetails.documentPreferencesModel
                            .senderStatus !== "completed"
                            ? false
                            : true
                        }
                        className="w-full px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                        value={ReviewPageDetails?.mailSubject}
                        name="mailSubject"
                        // placeholder="Please sign this document - abc.pdf"
                        type="text"
                        onChange={(e) => {
                          handleChangeDoc(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-start  w-full mt-6 opacity-80">
                    <div className="flex flex-col items-start relative w-full">
                      <p className="text-black absolute top-[-25px]">
                        Message (Optional)
                      </p>
                      <textarea
                        readOnly={
                          ReviewPageDetails.documentPreferencesModel
                            .senderStatus !== "completed"
                            ? false
                            : true
                        }
                        className="w-full h-10 max-h-10  px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                        value={ReviewPageDetails?.mailMessage}
                        name="mailMessage"
                        maxLength={limit}
                        // placeholder="Message..."
                        onChange={(e) => {
                          handleMessageChange(e);
                        }}
                      />
                      <span className="text-black">
                        {mailMessageLength}/{limit}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-start  w-full mt-8 opacity-80">
                    <div className="flex flex-col items-start relative w-full">
                      <p className="text-black absolute top-[-25px]">
                        Documents
                      </p>
                      {ReviewPageDetails && ReviewPageDetails?.documentList ? (
                        <FileView
                          data={ReviewPageDetails?.documentList}
                          showDelete={false}
                        />
                      ) : null}
                    </div>
                  </div>

                  <div className="flex items-center justify-start  w-full mt-6 opacity-80">
                    <div className="flex flex-col items-start relative w-full">
                      <p className="text-black absolute top-[-25px]">
                        Recipients
                      </p>
                      {ReviewPageDetails?.recipientList
                        ?.sort(sortByOrder)
                        ?.map((rcp: any) => {
                          console.log("rcp,", rcp);
                          return (
                            <>
                              <div className="flex items-center justify-between w-full mt-2 border border-black rounded-md p-2">
                                <p className="text-black text-lg pr-4 border-r">
                                  {ReviewPageDetails.documentPreferencesModel
                                    .signingOrder
                                    ? rcp?.signingOrder
                                    : ""}
                                </p>
                                <div className="flex flex-col items-start relative">
                                  <input
                                    className="w-full px-[10px] py-[2px]  rounded-md text-black focus:outline-none"
                                    placeholder="Name"
                                    type="text"
                                    disabled
                                    value={rcp?.name}
                                  />
                                </div>
                                <div className="flex items-center justify-center ml-4 relative">
                                  <BiMailSend
                                    size={35}
                                    className="text-black mr-1"
                                  />
                                  <input
                                    className="w-full px-[10px] py-[2px] rounded-md  text-black focus:outline-none"
                                    placeholder="Email"
                                    type="email"
                                    disabled
                                    value={rcp?.emailId}
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="w-full border-secondary bg-primary-900 p-4 rounded-5 border-opacity-70 relative pb-6 drop-shadow-md mt-4">
              {loading ? (
                <div className="w-full">
                  <LinearProgress
                    color="inherit"
                    style={{
                      backgroundColor: "red",
                    }}
                  />
                </div>
              ) : (
                <div className="flex items-center justify-start  w-full mt-6 opacity-80">
                  <div className="flex flex-col items-start relative w-full">
                    <p className="text-black absolute top-[-25px]">
                      Document Preferences
                    </p>

                    <div>
                      <CheckBox
                        label="Notify when document is viewed"
                        checked={
                          ReviewPageDetails?.documentPreferencesModel
                            ?.notifyDocumentViewed === true
                        }
                        handleCheckBoxChange={(e) => {
                          handleChangeCheckboxDoc(e, "notifyDocumentViewed");
                        }}
                        disabled={
                          ReviewPageDetails.documentPreferencesModel
                            .senderStatus !== "completed"
                            ? false
                            : true
                        }
                      />
                    </div>
                    <div>
                      <CheckBox
                        label="Notify when document is signed by each signer"
                        checked={
                          ReviewPageDetails?.documentPreferencesModel
                            ?.notifyDocumentSigned === true
                        }
                        handleCheckBoxChange={(e) => {
                          handleChangeCheckboxDoc(e, "notifyDocumentSigned");
                        }}
                        disabled={
                          ReviewPageDetails.documentPreferencesModel
                            .senderStatus !== "completed"
                            ? false
                            : true
                        }
                      />
                    </div>
                    {/*  <div>
                      <CheckBox
                        label="Notify on document completed"
                        checked={
                          ReviewPageDetails?.documentPreferencesModel
                            ?.notifyDocumentCompleted === true
                        }
                        handleCheckBoxChange={(e) => {
                          handleChangeCheckboxDoc(e, "notifyDocumentCompleted");
                        }}
                      />
                    </div> */}
                    <div>
                      <CheckBox
                        label="Enable Auto reminders"
                        checked={
                          ReviewPageDetails?.documentPreferencesModel
                            ?.enbaleDocumentAutoRemider === true
                        }
                        handleCheckBoxChange={(e) => {
                          handleChangeCheckboxDoc(
                            e,
                            "enbaleDocumentAutoRemider"
                          );
                        }}
                        disabled={
                          ReviewPageDetails.documentPreferencesModel
                            .senderStatus !== "completed"
                            ? false
                            : true
                        }
                      />
                    </div>
                    {/* <div>
                      <CheckBox
                        label="Attach Audit Trail In Documents"
                        checked={
                          ReviewPageDetails?.documentPreferencesModel
                            ?.enableAuditTrailAttach === true
                        }
                        handleCheckBoxChange={(e) => {
                          handleChangeCheckboxDoc(e, "enableAuditTrailAttach");
                        }}
                        disabled={
                          ReviewPageDetails.documentPreferencesModel
                            .senderStatus !== "completed"
                            ? false
                            : true
                        }
                      />
                    </div> */}

                    <div className="flex flex-col items-start relative w-1/4 mt-6">
                      <p className="text-black absolute top-[-30px]">
                        Expire Document After
                      </p>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Duration
                        </InputLabel>
                        <Select
                          readOnly={
                            ReviewPageDetails.documentPreferencesModel
                              .senderStatus !== "completed"
                              ? false
                              : true
                          }
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            ReviewPageDetails?.documentPreferencesModel
                              ?.expireDaysInfoId
                          }
                          label="Select Duration"
                          onChange={(e) => {
                            handleChange(e, "expireDaysInfoId");
                          }}
                        >
                          {ReviewPageDetails?.documentValidityPeriodList?.map(
                            (days: any) => (
                              <MenuItem value={days?.id} key={days?.daysTitle}>
                                {days?.daysTitle}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
