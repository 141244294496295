import axios from "axios";
import { useSnackbar } from "notistack";
import React, { createContext, useEffect, useReducer } from "react";
import { Managereducer } from "./manage.reducer";

export const initialState: any = {
  documentDetails: [],
  allpendingdocument: [],
  currentDocumentHeaderId: "",
  loading: true,
};

const ManageContext = createContext(initialState);

export default ManageContext;

interface ManageProviderProps {
  children: React.ReactNode;
}

const baseURL = process.env.REACT_APP_API_URL;
const headerKey = process.env.REACT_APP_API_HEADER_KEY;

export const ManageProvider: React.FC<ManageProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(Managereducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  const getAllPendingDocuments = async (
    userId: string,
    companyId: string,
    type: string
  ) => {
    const urlPath = window.location.pathname;

    const url = urlPath.includes("/template")
      ? `${baseURL}/template/get-template-header?userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem("companyId")}&status=${type}`
      : `${baseURL}/upload/get-pending-documents-header?userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem("companyId")}&status=${type}`;

    await axios
      .get(url, {
        headers: {
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        dispatch({
          type: "GET_ALLPENDINGDOCUMENT",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const getDocumentDetails = async (
    userId: string,
    companyId: string,
    documentHeaderId: string
  ) => {
    if (!userId || !companyId) {
      return;
    }
    dispatch({
      type: "SET_LOADING",
    });
    await fetch(
      `${baseURL}/upload/get-document-detail?userId=${sessionStorage.getItem(
        "userId"
      )}&companyId=${sessionStorage.getItem(
        "companyId"
      )}&documentHeaderId=${documentHeaderId}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      }
    )
      .then((res) => {
        if (res.status === 204) {
          return [];
        }
        return res.json();
      })
      .then((res) => {
        console.log({ res });
        dispatch({
          type: "GET_DOCUMENTDETAILS",
          payload: res,
        });
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const setCurrentDocumentHeaderId = (documentHeaderId: string) => {
    dispatch({
      type: "SET_CURRENTDOCUMENTHEADERID",
      payload: documentHeaderId,
    });
  };

  useEffect(() => {
    const currentDocumentHeaderId = localStorage.getItem(
      "currentDocumentHeaderId"
    );
    if (currentDocumentHeaderId) {
      let userId = sessionStorage.getItem("userId") || "";
      let companyId = sessionStorage.getItem("companyId") || "";
      setCurrentDocumentHeaderId(currentDocumentHeaderId);
      getDocumentDetails(userId, companyId, currentDocumentHeaderId);
    }
  }, []);

  return (
    <ManageContext.Provider
      value={
        {
          ...state,
          getDocumentDetails,
          getAllPendingDocuments,
          setCurrentDocumentHeaderId,
        } as any
      }
    >
      {children}
    </ManageContext.Provider>
  );
};
