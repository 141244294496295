import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider, LinearProgress } from "@mui/material";
import styles from "../../styles/Sign.module.css";
import { SignnatureTabs } from "./SignnatureTabs";
import SignatureContext from "../../context/signature/Signature.context";
import { getInitials } from "./SignPopupLocal";

interface SignaturePopupProps {
  modal: boolean;
  setShowModal: (value: boolean) => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const SignaturePopup: React.FC<SignaturePopupProps> = ({
  modal,
  setShowModal,
}) => {
  const {
    loading,
    isDefault,
    isDeleted,
    userId,
    companyId,
    setSignImage,
    signKey,
    userName,
    signText,
  } = useContext<any>(SignatureContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [signDetails, setSignDetails] = useState<SignDetailsType>({
    fullName: signText ? signText : userName,
    initials: getInitials(signText ? signText : userName),
  });

  const [signInitialsDetails, setSignInitialsDetails] = useState<any>({
    signImage: "",
    initialImage: "",
    fontId: null,
    types: "Text",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSignDetails({
      ...signDetails,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    let userId = sessionStorage.getItem("userId") || "";
    let companyId = sessionStorage.getItem("companyId") || "";

    const ini = getInitials(
      signDetails.fullName ? signDetails.fullName : userName
    );

    const data = {
      fontId: signInitialsDetails.fontId
        ? signInitialsDetails.fontId
        : undefined,
      signImage:
        signInitialsDetails.signImage === ""
          ? undefined
          : signInitialsDetails.signImage,
      initialImage:
        signInitialsDetails.initialImage === ""
          ? undefined
          : signInitialsDetails.initialImage,

      signText: signDetails.fullName === "" ? undefined : signDetails.fullName,

      // initialText:
      //   signDetails.initials === "" ? undefined : signDetails.initials,

      initialText: signDetails.initials === "" ? undefined : ini,

      isDefault,
      isDeleted,
      userId,
      companyId,
      signId: signKey,
    };
    console.log({ data });
    setSignImage(data);
    setShowModal(false);
  };

  return (
    <div>
      <BootstrapDialog
        fullScreen={fullScreen}
        onClose={() => setShowModal(false)}
        aria-labelledby="customized-dialog-title"
        open={modal}
        scroll="paper"
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowModal(false)}
        >
          <h4>Create Your Signature</h4>
        </BootstrapDialogTitle>
        <Divider />
        <BootstrapDialogTitle id="customized-dialog-title">
          <div className={``}>
            <div className={`flex items-center justify-start `}>
              <div className="flex items-center justify-center">
                <p className="text-black mr-3">Full Name</p>
                <input
                  className={`${styles.input}
                     
                     `}
                  // className={`${styles.input}`}
                  style={{ width: "400px" }}
                  name="fullName"
                  value={signDetails?.fullName}
                  onChange={onChange}
                  onClick={(e: any) => (e.target as any).select()}
                  // disabled
                />
              </div>
              <div className="flex items-center justify-center mx-6">
                <p className="text-black mr-3">Initials</p>
                <input
                  className={`${styles.input} cursor-not-allowed`}
                  name="initials"
                  value={signDetails.initials}
                  onChange={onChange}
                  onClick={(e: any) => (e.target as any).select()}
                  disabled
                />
              </div>
            </div>
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {loading ? (
            <LinearProgress />
          ) : (
            <SignnatureTabs
              fullName={signDetails.fullName}
              initials={signDetails.initials}
              signInitialsDetails={signInitialsDetails}
              setSignInitialsDetails={setSignInitialsDetails}
            />
          )}
        </DialogContent>
        <DialogActions className="flex-col items-end mx-2">
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className="opacity-80 my-4"
          >
            By clicking Create, I agree that the signature and initials will be
            the electronic representation of my signature and initials for all
            purpose when I (or my client) use them on envelopes, including
            legally binding contracts - just the same as a pen-and-paper
            signature or initials.
          </Typography>
          <div className="w-full mt-2">
            <Button onClick={() => handleSubmit()} variant="contained">
              Create
            </Button>
            <Button
              onClick={() => setShowModal(false)}
              variant="contained"
              color="warning"
              style={{
                marginLeft: "1rem",
              }}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

type SignDetailsType = {
  fullName: string;
  initials: string;
};
