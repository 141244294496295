import React, { useState } from "react";
import { Button, Checkbox, Typography, Link, Grid } from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import AgreementModal from "./AgreementModal";

const MainModal = ({ isOpen, onClose, userName, userEmail }) => {
  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleAgreeClick = () => {
    if (isChecked) {
      onClose(); // Close the modal only when "Agree" is clicked
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {/* Full-screen blurred overlay */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          backdropFilter: "blur(0.5px)", // Blur effect
          zIndex: 1200,
        }}
      />

      {/* Modal Content */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          width: "100%",

          // backgroundColor: "lightpink",
          backgroundColor: "#f0f8ff",

          zIndex: 1300,
          padding: "20px 50px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Left Section */}
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              paddingRight: "24px",
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontWeight: "bold",
                marginBottom: "16px",
                color: "black",
                fontSize: "0.85rem",
              }}
            >
              Please read the
              <FileCopy
                style={{
                  marginRight: "5px",
                  marginLeft: "5px",
                  color: "#007BFF",
                  fontSize: "1rem",
                }}
              />
              <Link
                component="button"
                onClick={() => setAgreementModalOpen(true)}
                style={{
                  color: "#007BFF",
                  textDecoration: "none",
                  fontSize: "0.8rem",
                }}
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Electronic Record and Signature Disclosure.
              </Link>
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.75rem",
              }}
            >
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
                style={{ transform: "scale(0.8)", marginRight: "1px" }}
              />
              <Typography
                variant="body2"
                style={{
                  fontSize: "0.75rem",
                  color: "black",
                }}
              >
                I agree to use electronic records and signatures.
              </Typography>
            </div>
          </Grid>

          {/* Right Section */}
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleAgreeClick}
              disabled={!isChecked}
              style={{
                fontSize: "0.8rem",
                padding: "6px 12px",
              }}
            >
              Agree
            </Button>
          </Grid>
        </div>
      </div>

      {/* Agreement Modal */}
      {isAgreementModalOpen && (
        <AgreementModal
          isOpen={isAgreementModalOpen}
          onClose={() => setAgreementModalOpen(false)}
          userName={userName}
          userEmail={userEmail}
        />
      )}
    </>
  );
};

export default MainModal;
