import axios from "axios";
import { useSnackbar } from "notistack";
import React, { createContext, useContext, useReducer, useState } from "react";
import ManageContext from "../manage/manage.context";

import { documentDetailsReducer } from "./documentDetails.reducer";

const initialState: any = {
  recipientSuggestions: [],
  recipientSuggestionsLoading: true,
  recipientList: [],
  signingOrder: [],
  documentDetails: [],
  savedRecipientList: [],
  placeholderdata: {},
  loading: true,
  placeholderLoading: true,
};

const DocumentDetailsContext = createContext(initialState);

export default DocumentDetailsContext;

interface DocumentDetailsProviderProps {
  children: React.ReactNode;
}

const baseURL = process.env.REACT_APP_API_URL;
const headerKey = process.env.REACT_APP_API_HEADER_KEY;

export const DocumentDetailsProvider: React.FC<
  DocumentDetailsProviderProps
> = ({ children }) => {
  const { currentDocumentHeaderId } = useContext(ManageContext);
  const [state, dispatch] = useReducer(documentDetailsReducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const [userID_CID, setuserID_CID] = useState<any>();

  const getRecipientSuggestions = async (
    // userId: string,
    // companyId: string,
    firstName?: string,
    email?: string
  ) => {
    // setuserID_CID({ userId, companyId });
    let body = {
      firstName: firstName ? firstName : "",
      emailId: email ? email : "",
    };
    // console.log("body", body);

    await fetch(
      `${baseURL}/recipient/get-recipient/${sessionStorage.getItem(
        "userId"
      )}/${sessionStorage.getItem("companyId")}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
        body: JSON.stringify(body),
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        console.log("api responcess ", res);
        if (res.length >= 0) {
          dispatch({
            type: "SET_RECIPIENT_SUGGESTIONS",
            payload: res,
          });
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };
  const removeRecipientSuggestion = async () => {
    return dispatch({
      type: "SET_RECIPIENT_SUGGESTIONS",
      payload: [],
    });
  };

  const getPreprationPageDetails = async () => {
    dispatch({
      type: "LOADING",
    });

    await fetch(
      `${baseURL}/upload/get-document-preparation-data?userId=${sessionStorage.getItem(
        "userId"
      )}&companyId=${sessionStorage.getItem(
        "companyId"
      )}&documentHeaderId=${currentDocumentHeaderId}`,
      {
        headers: {
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);
        dispatch({
          type: "GET_DOCUMENTDETAILS",
          payload: res,
        });
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const setRecipientList = async (data: any, signingOrder: boolean) => {
    let body = {
      documentHeaderId: currentDocumentHeaderId,
      recipientList: [...data],
      signingOrder: signingOrder,
    };

    await fetch(
      `${baseURL}/recipient/assign-recipients/${sessionStorage.getItem(
        "userId"
      )}/${sessionStorage.getItem("companyId")}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
        body: JSON.stringify(body),
      }
    )
      .then((res) => res.text())
      .then(async (res) => {
        console.log(res);
        enqueueSnackbar(res, { variant: "success" });

        let currenetdobj = JSON.parse(
          sessionStorage.getItem("RecipientsList") || ""
        );

        delete currenetdobj[currentDocumentHeaderId];

        sessionStorage.setItem("RecipientsList", JSON.stringify(currenetdobj));
        getPreprationPageDetails();
        getRecipientSavedState();
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const getRecipientSavedState = async () => {
    dispatch({
      type: "LOADING",
    });
    await fetch(
      `${baseURL}/recipient/get-assign-sign-recipients?userId=${sessionStorage.getItem(
        "userId"
      )}&companyId=${sessionStorage.getItem(
        "companyId"
      )}&documentHeaderId=${currentDocumentHeaderId}`,
      {
        headers: {
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      }
    )
      .then((res) => {
        if (res.status === 204) {
          return [];
        }
        return res.json();
      })
      .then(async (res) => {
        // enqueueSnackbar(res, { variant: "success" });
        // res.documentDetails = [res.documentDetails[0], res.documentDetails[0]];
        console.log({ res });
        let savedRecipientList: any[] = res.map((r: any) => ({
          name: r.name,
          emailId: r.emailId,
          id: r.id,
          signingOrder: r.signingOrder,
          colorCode: r.colorCode,
        }));
        dispatch({
          type: "SAVED_RECIPIENT_LIST",
          payload: savedRecipientList,
        });
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const deleteRecipient = async (id: any) => {
    await fetch(
      `${baseURL}/recipient/delete-recipients?userId=${sessionStorage.getItem(
        "userId"
      )}&companyId=${sessionStorage.getItem(
        "companyId"
      )}&documentHeaderId=${currentDocumentHeaderId}&recipientId=${id}`,
      {
        method: "DELETE",
        headers: {
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      }
    )
      .then((res) => res.text())
      .then((res) => {
        console.log({ res });
        getRecipientSavedState();
        enqueueSnackbar(res, { variant: "success" });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const getPlaceholderData = async (docmenHeaderId: any) => {
    dispatch({
      type: "PLACEHOLDER_LOADING",
    });
    return await fetch(
      `${baseURL}/upload/get-sign-component-placeholder-document?userId=${sessionStorage.getItem(
        "userId"
      )}&companyId=${sessionStorage.getItem(
        "companyId"
      )}&documentDetailId=${docmenHeaderId}`,
      {
        headers: {
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        dispatch({
          type: "PLACEHOLDER_DATA",
          payload: res,
        });
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const setPlaceHolderData = async (data: any, isDocumentSwitch: boolean) => {
    dispatch({
      type: "LOADING",
    });
    console.log("data", data);
    await fetch(
      `${baseURL}/upload/save-sign-component-placeholder-document/${sessionStorage.getItem(
        "userId"
      )}/${sessionStorage.getItem("companyId")}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
        body: JSON.stringify(data),
      }
    )
      // .then((res) => res.json())
      .then(async (res) => {
        if (!isDocumentSwitch) {
          getPlaceholderData(data?.documentDetailsId);
        }
        console.log(res);
        dispatch({
          type: "LOADING_FALSE",
        });
        dispatch({
          type: "PLACEHOLDER_LOADING_FALSE",
        });
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const deleteDocument = async (id: any) => {
    console.log("DELETE DOCUMENT", id);
    let userId = sessionStorage.getItem("userId") || "";
    let companyId = sessionStorage.getItem("companyId") || "";
    let data = new FormData();
    data.set("userId", userId);
    data.set("companyId", companyId);
    data.set("documentDetailId", id);
    await axios
      .delete(`${baseURL}/upload/delete_document`, {
        headers: {
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
        data: data,
      })
      .then((res) => {
        console.log({ res });
        getPreprationPageDetails();
        enqueueSnackbar(res?.data, { variant: "success" });
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar(err?.response?.data, { variant: "error" });
      });
  };

  const deleteSignComponent = async (
    documentDetailId: any,
    componentId: any
  ) => {
    // console.log("DELETE COMPONENT",documentDetailId,componentId)
    let userId = sessionStorage.getItem("userId") || "";
    let companyId = sessionStorage.getItem("companyId") || "";
    let data = new FormData();
    data.set("userId", userId);
    data.set("companyId", companyId);
    data.set("componentId", componentId);
    data.set("documentDetailId", documentDetailId);
    return await axios.delete(`${baseURL}/upload/delete-sign-component`, {
      headers: {
        key: `${headerKey}`,
        Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
      },
      data: data,
    });
    // .then((res) => {
    //   console.log({ res });
    //   // getPlaceholderData(documentDetailId);
    //   enqueueSnackbar(res?.data, { variant: "success" });
    // })
    // .catch((err) => {
    //   console.log({ err });
    //   enqueueSnackbar(err?.response?.data, { variant: "error" });
    // });
  };
  return (
    <DocumentDetailsContext.Provider
      value={{
        ...state,
        getRecipientSuggestions,
        setRecipientList,
        getPreprationPageDetails,
        getRecipientSavedState,
        getPlaceholderData,
        setPlaceHolderData,
        deleteRecipient,
        deleteDocument,
        removeRecipientSuggestion,
        deleteSignComponent,
      }}
    >
      {children}
    </DocumentDetailsContext.Provider>
  );
};
