import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Login.module.css";
import Lottie from "react-lottie";
import { validateEmail } from "./helpers/Helpers";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import TermAndConditions_PDF from "../assets/EASYSIGN Terms And Conditions.pdf";
import PrivacyPolicyPDF from "../assets/EASYSIGN Privacy Notice.pdf";

interface LoginProps {
  onFormSwitch?: (message: any) => void;
}

const baseURL = process.env.REACT_APP_API_URL;

export const Registration: React.FC<LoginProps> = ({
  onFormSwitch,
}: LoginProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string>("");

  const [password, setPassword] = useState("");

  const [country, setCountry] = useState("United States of America");
  const [countries, setCountries] = useState<string[]>([]);

  const [termsAccepted, setTermsAccepted] = useState(false);

  // const [termsAccepted, setTermsAccepted] = useState(true);

  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const validateEmail = (email: string) => {
    // Simple email regex to check for a valid format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email === "") {
      setError("Email is required.");
    } else if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
    } else {
      setError(""); // No error if the email is valid
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    validateEmail(e.target.value); // Validate on input change
  };

  const countryArray = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  useEffect(() => {
    setCountries(countryArray);
  }, []);

  async function signUp() {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,20}$/;

    const validateEmail = (email: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      const domainPart = email.split("@")[1];

      const repeatedDomainPattern = /\.(\w+)\.\1\b/;

      if (repeatedDomainPattern.test(domainPart)) {
        enqueueSnackbar(
          "Domain part of the email contains repetitive sections",
          { variant: "error" }
        );
        return;
      }

      if (!emailRegex.test(email)) {
        enqueueSnackbar("Invalid email format", { variant: "error" });
        return;
      }

      enqueueSnackbar("Email is valid", { variant: "success" });
      return true;
    };

    if (email === "" || password === "" || name === "") {
      enqueueSnackbar("All fields are required. Please fill out every field.", {
        variant: "warning",
      });
      return;
    }

    if (!passwordRegex.test(password)) {
      enqueueSnackbar(
        "Password must be 8-20 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character.",
        { variant: "warning" }
      );
      return;
    }

    // Check for a valid email format
    if (!validateEmail(email)) {
      enqueueSnackbar("Please enter a valid email address.", {
        variant: "warning",
      });
      return;
    }

    if (!country) {
      enqueueSnackbar("Please select a country.", { variant: "warning" });
      return;
    }

    // if (!captchaValue) {
    //   // Ensure reCAPTCHA is completed
    //   enqueueSnackbar("Please complete the CAPTCHA verification.", {
    //     variant: "warning",
    //   });
    //   return;
    // }

    // if (!termsAccepted) {
    //   enqueueSnackbar("You must accept the terms and conditions", {
    //     variant: "warning",
    //   });
    //   return;
    // }

    // navigate("/otp_verification");

    let item = { name, email, password, username: email, country, role: [] };

    // console.log("gaydgyueguydegjhfgebjhfebjhfbehjfcbehjbehjfbhej",item);

    setLoading(true);
    try {
      const res = await axios.post(`${baseURL}/auth/signup`, item);
      console.log("Response after registration :", res);
      if (res.data.status === false && res.data.message) {
        enqueueSnackbar(res.data?.message, { variant: "error" });
        navigate("/login");
      } else {
        enqueueSnackbar("Registration Successfull", { variant: "success" });
        navigate("/login");
        // navigate("/pay?email=" + email + "&name=" + name);

        // navigate("/myPay?email=" + email + "&name=" + name);

        // navigate("/otp_verification");
      }
    } catch (error) {
      enqueueSnackbar("An error occurred during registration", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const handleTermsAndConditions = () => {
    // window.open(TermAndConditions_PDF, "_blank");
    window.open(
      "https://www.easysign.us.com/easysign-sites-services-terms-and-conditions/",
      "_blank"
    );
  };

  const handlePrivacyPolicy = () => {
    // window.open(PrivacyPolicyPDF, "_blank");
    window.open(
      "https://www.easysign.us.com/privacy-notice-easysign/",
      "_blank"
    );
  };

  return (
    <>
      <div className={styles.loginContainer}>
        <div className={styles.title}>
          <p>EasySign</p>
        </div>

        <div className={styles.left}>
          <div className={styles.lottie}>
            <Lottie
              style={{
                borderRadius: "10%",
              }}
              options={{
                loop: true,
                autoplay: true,
                animationData: require(`../assets/Login.json`),
              }}
            />
          </div>
        </div>

        <div className={styles.border} />

        <div className={styles.right}>
          <div className={styles.card}>
            <div
              className={styles.loginForm}
              style={{
                height: "80vh",
                overflowY: "scroll",
              }}
            >
              <div className={styles.loginTitle}>
                <p className={styles.upperInfo}>Sign Up to your account</p>
              </div>

              <div
                style={{
                  margin: "10px 0",
                  padding: "0 35px",
                  textAlign: "center",
                  paddingBottom: "1rem",
                }}
              >
                <label
                  style={{
                    color: "black",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                  }}
                >
                  Try EasySign free for 30 days! No credit card needed.
                </label>
              </div>

              <div className={styles.feild}>
                <div className={styles.label}>
                  <p>Name</p>
                </div>
                <input
                  value={name}
                  className={styles.inputFeild}
                  type="text"
                  name="name"
                  placeholder="Type in your name.."
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value)
                  }
                />
              </div>

              <div className={styles.feild}>
                <div className={styles.label}>
                  <p>Email</p>
                </div>
                <input
                  value={email}
                  className={styles.inputFeild}
                  type="email"
                  name="email"
                  placeholder="Type in your email.."
                  required
                  onChange={handleEmailChange}
                />
                {/* Conditionally render the error message */}
                {error && (
                  <p style={{ color: "red" }} className={styles.errorMessage}>
                    {error}
                  </p>
                )}
              </div>

              <div className={styles.feild}>
                <div className={styles.label}>
                  <p>Password</p>
                </div>
                <input
                  value={password}
                  className={styles.inputFeild}
                  type="password"
                  name="password"
                  placeholder="Type in your password.."
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                />
              </div>

              <div className={styles.feild}>
                <div className={styles.label}>
                  <p>Country</p>
                </div>
                <select
                  value={country}
                  className={styles.inputFeild}
                  name="country"
                  required
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setCountry(e.target.value)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <option value="" disabled>
                    Select your country...
                  </option>
                  {countries.map((countryName, index) => (
                    <option key={index} value={countryName}>
                      {countryName}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className={styles.feild}>
                <div className={styles.label}>
                  <p>CAPTCHA Verification</p>
                </div>
                <ReCAPTCHA
                  sitekey="YOUR_GOOGLE_RECAPTCHA_SITE_KEY"
                  onChange={handleCaptchaChange}
                />
              </div>
              <br></br> */}

              <div style={{ margin: "10px 0", padding: "0 35px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTermsAccepted(e.target.checked)
                    }
                    style={{ marginRight: "10px", cursor: "pointer" }}
                  />
                  <label
                    htmlFor="terms"
                    style={{
                      color: "black",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    I agree to receive marketing communications from EasySign
                    and acknowledge that I can opt out at any time by visiting
                    the Preference Center.
                  </label>
                </div>
              </div>

              <div
                style={{
                  margin: "10px 0",
                  padding: "0 35px",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    color: "black",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  By clicking the Register button below, you agree to the{" "}
                  <a
                    // href="#"
                    onClick={handleTermsAndConditions}
                    style={{ color: "#007bff", textDecoration: "none" }}
                  >
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    // href="#"
                    onClick={handlePrivacyPolicy}
                    style={{ color: "#007bff", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>

              <button
                className={styles.btn}
                onClick={signUp}
                disabled={loading}
              >
                Register
                {loading && (
                  <CircularProgress
                    size={20}
                    color={"inherit"}
                    className={styles.loader}
                  />
                )}
              </button>
              <div style={{ paddingTop: "3%" }}>
                <Link to="/login">
                  <p style={{ color: "black" }}>
                    Already have an account? Login here!
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
